import ItemList from "@/ee09/models/ItemList";
import ImageItem from "@/ee09/models/ImageItem";
import BlockItem from "@/ee09/fields/BlockItem";
import ImageField from "@/ee09/fields/ImageField";

export default class AccordionLocationBlock extends BlockItem{
    constructor() {
        super();
        this.blockType="accordion-location-block";

        /**
         * Petit numéro à côté du titre
         * @type {string}
         */
        this.prefix="";
        /**
         * Titre nom du produit
         * @type {string}
         */
        this.title="";
        /**
         *
         * @type {ImageItemList}
         */
        this.photos=new ItemList(
            function(imageField){
                return new ImageItem(imageField);
            }
        );
        /**
         * Texte à droite de la photo
         * @type {string}
         */
        this.txt1="";
        /**
         * Texte bas gauche
         * @type {string}
         */
        this.txt2="";
        /**
         * Texte bas droite
         * @type {string}
         */
        this.txt3="";




    }

    mount(data) {
        super.mount(data);
        this.photos._processData();
        for(let i of this.photos.items){
            if(!i.image){
                i.image=new ImageField();
            }
        }
    }
}